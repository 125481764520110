@import "../../assets/styles/common/colors.scss";
@import "../../assets/styles/common/mixins.scss";

.border-bottom {
  border-bottom: 1px solid $spn-500-color;
}

.card-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  &__heading {
    @include text(700, 22px, normal, $pure-black);
    letter-spacing: 0.25px;
  }
  &__desc {
    @include text(400, 14px, 16px, $spn-500-color);
    letter-spacing: 0.4px;
  }
  // margin: 24px 0;
  // padding-bottom: 24px;
  //   @include text(600, 12px, 16px, $spn-500-color);

  // .header {
  //   margin-bottom: 16px;
  // }

  // .description {
  //   @include text(400, 14px, 16px, $spn-500-color);
  //   letter-spacing: 0.4px;
  // }
}
