@import "../../assets/styles/common/colors.scss";
.daterange-filter {
  width: 676px;
}

.DateRangePicker {
  &__CalendarSelection {
    background-color: $primary-color;
    border: 1px solid $primary-color2;
  }
}

@media only screen and (max-width: 680px) {
  .daterange-filter {
    width: 100%;
  }
}
