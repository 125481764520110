@import "../../../assets/styles/common/colors.scss";
@import "../../../assets/styles/common/mixins.scss";
.view-card-details {
  .instr {
    @include text(600, 12px, 24px, $spn-900-color);
    border-radius: 8px;
    border: 1px solid $spy-500-color;
    background-color: $spy-100-color;
    letter-spacing: 0.2px;
    padding: 10px 1rem;
  }
  .mt-200 {
    margin-top: 120px;
  }
}
