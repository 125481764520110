@import "../../assets/styles/common/colors.scss";
@import "../../assets/styles/common/mixins.scss";

.label-item {
  @include text(500, 14px, 16px, $spn-500-color);
  letter-spacing: 0.4px;

  &.font-600 {
    font-weight: 600;
  }

  &.light {
    color: $spn-500-color;
  }

  &.dark {
    color: $spn-900-color;
  }
}

.h_5 {
  height: 80px;
}
