@import "../../assets/styles/common/mixins.scss";

.success-modal-container {
  margin-bottom: 0;
  margin-top: 3.5rem;

  .modal-content {
    min-height: 72vh;
    border-radius: 8px;

    .modal-body {
      // display: flex;
      // min-height: 45vh;
    }
  }
  .content-wrapper {
    min-width: 250px;
    margin: auto;

    .success-title {
      text-align: center;
      letter-spacing: 0.002em;
      @include text(600, 24px, 30px, $pure-black);
    }

    .success-content {
      @include text(400, 16px, 30px, $spn-500-color);
      text-align: center;
      margin-top: 0;
      letter-spacing: 0.002em;
    }
  }
}
