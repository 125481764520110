@import "../../assets/styles/common/colors.scss";
@import "../../assets/styles/common/mixins.scss";

.max-w-400 {
  max-width: 400px;

  .modal-content {
    border-radius: 10px;
    border: none;
  }
}

.sp-modal {
  &__title {
    @include text(700, 22px, normal, $pure-black);
    letter-spacing: 0.25px;
  }

  &__subtitle {
    @include text(400, 14px, 16px, $spn-500-color);
    letter-spacing: 0.4px;
  }

  &__body {
    padding-top: 0px !important;
    position: static;
  }

  .gap-4 {
    gap: 1rem;
  }
}

.border-bottom {
  border-bottom: 1px solid $spn-500-color;
}

.data-card {
  margin: 24px 0;
  padding-bottom: 24px;

  .header {
    @include text(600, 12px, 16px, $spn-500-color);
    margin-bottom: 16px;
  }

  .description {
    @include text(400, 14px, 16px, $spn-500-color);
    letter-spacing: 0.4px;
  }
}
