@import "../../assets/styles/common/colors.scss";
@import "../../assets/styles/common/mixins.scss";

.dd_menu {
  border-radius: 6px;
  border: 1px solid $spn-100-color;

  &-item {
    padding: 10px 1rem !important;
    @include text(400, 14px, 16px, $spn-900-color);
    letter-spacing: 0.4px;
    &:hover,
    &:focus-within,
    &:focus-visible,
    &:active {
      font-weight: 600;
      background-color: $spn-500-color;
    }
  }
}
