@import "../../assets/styles/common/colors.scss";
@import "../../assets/styles/common/mixins.scss";

.analytics-wrapper {
  .active {
    color: $spg-500-color;
    background: $spg-100-color;
    border: 1px solid $spn-050-color;
    border-radius: 4px;
  }

  .blocked {
    background-color: rgba($spr-500-color, 0.25);
    color: $spr-500-color;
    border: 1px solid $spn-050-color;
    border-radius: 4px;
  }

  .rotate {
    transform: rotate(180deg);
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    transition-delay: 75ms;
  }
}

.success {
  color: $spg-500-color;
  background: $spg-100-color;
  border-radius: 4px;
}
.search-container {
  position: relative;
  font-size: 16px;
  display: inline-block;
  input {
    width: 300px;
    height: 40px !important;
    padding-right: 30px;
  }
  i {
    position: absolute;
    top: 9px;
    right: 15px;
  }
}
.dropdownToggleButton {
  height: 40px !important;
}

.failed {
  background-color: $spr-100-color;
  color: $spr-500-color;
  border-radius: 4px;
}

.card-action {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16px !important;

  letter-spacing: 0.028px;
}
