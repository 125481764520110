@import "../../assets/styles/common/colors.scss";
@import "../../assets/styles/common/mixins.scss";
.custom-tab-nav {
  .sp-navlink {
    text-transform: capitalize;
    padding: 0.5rem;
    @include text(500, 12px, 16px, $gray-500);
    cursor: pointer;
    &.pa-0 {
      padding: 0;
    }
    &.active {
      border-width: 80%;
      color: $primary-color !important;
      &::after {
        display: block;
        clear: both;
        content: "";
        position: relative;
        left: 0;
        bottom: 0;
        max-width: 250px;
        height: 1px;
        width: 100%; /* or 100px */
        border-bottom: 2px solid $primary-color !important;
        margin: 0 auto;
        padding: 4px 0px;
      }
      .activeSub {
        color: $primary-color !important;
      }
    }
  }
}
