@import "../../../assets/styles/common/colors.scss";
@import "../../../assets/styles/common/mixins.scss";

.onboard-currency-wrapper {
  .wrap-search {
    padding-block-end: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    input {
      width: 100%;
      padding: 6px 10px;
    }
  }

  .currencies-main {
    padding-block: 16px;

    &__instruction {
      letter-spacing: 0.4px;
      @include text(400, 12px, 16px, 16px, $darkBlue);
    }

    &__list {
      height: 55vh;
      overflow-y: auto;

      .currency_item {
        padding: 15px 16px;
        cursor: pointer;
        border-radius: 4px;
        font-size: 16px;
        transition: all 0.4;

        &.active {
          background-color: $spn-200-color;
        }

        &.enabled:hover {
          background-color: $spn-050-color;
          font-weight: 600;
        }

        &.disabled {
          opacity: 0.4;
          cursor: not-allowed;
        }
      }

      .status-key {
        height: 10px;
        width: 10px;

        &.available {
          background-color: $gray-100;
        }

        &.pending {
          background-color: $spo-100-color;
        }

        &.approved {
          background-color: $spg-100-color;
        }
      }
    }
  }

  .no-data {
    height: 180px;
  }

  .flex-1 {
    flex: 1;
  }
}