@import "../../assets/styles/common/colors.scss";
@import "../../assets/styles/common/mixins.scss";

.card_div {
  // width: 100%;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  padding: 17px;
  border: 1px solid $spn-100-color;

  .icon-contain {
    height: 32px;
    width: 32px;
    border-radius: 8px;
  }
}
