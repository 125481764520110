@import "~vgg-ui-react/dist/styles/partials";
.head-section {
  &__icon {
    background: #e8f0ff;
    border-radius: 6px;
    color: #175fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    padding: 10px;
    cursor: pointer;
    transition: opacity 0.3s;

    i {
      font-size: 25px;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &__title {
    .pg-title {
      font-weight: $sp-fontweight--600;
    }
    .pg-subtitle {
      font-weight: $sp-fontweight--600;
    }
  }
}
