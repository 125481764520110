@import "../../assets/styles/common/colors.scss";
.empty-container {
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -100px;

  .square-4 {
    height: 100px;
    width: 100px;
    background-color: $spb-100-color;
    color: $spb-600-color;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    margin: auto;
  }

  .btn-gray {
    outline: $spn-100-color;
    border-color: $spn-100-color;
    background-color: $pure-white;
    color: $spn-800-color;

    &:hover {
      background-color: $spn-050-color;
      outline: $spn-100-color;
      border-color: $spn-100-color;
      color: $spn-800-color;
    }
  }
}
