#pageMessages {
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 30%;
}

.alert {
  position: relative;
  top: 50%;
  bottom: 50%;
}

.alert .close {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 1em;
}

.alert .fa {
  margin-right: 0.3em;
}

.btn-danger {
  margin-top: 10px;
}

.center {
  margin: auto;
  width: 50%;
  margin-top: 100px;
}
