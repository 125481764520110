@import "../../assets/styles/common/colors.scss";
@import "../../assets/styles/common/mixins.scss";

.v_card {
  min-width: 352px;
  height: 200px;
  padding: 24px;
  background: url("../../assets/images/Circle.png");
  background-color: $blackishBlue-100;
  background-position: bottom right;
  background-repeat: no-repeat;
  border-radius: 8px;

  .holder,
  .status {
    @include text(600, 14px, 16px, $spn-000-color);
    letter-spacing: 0.028px;
    text-transform: capitalize;
  }

  .number {
    @include text(500, 24px, 24px, $spn-000-color);
    letter-spacing: 0.2px;
  }

  .key {
    @include text(400, 12px, 16px, $spn-000-color);
    letter-spacing: 0.4px;
  }

  .val {
    @include text(600, 14px, 16px, $spn-000-color);
    letter-spacing: 0.028px;
  }
}
