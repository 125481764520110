@import "../../assets/styles/common/colors.scss";
@import "../../assets/styles/common/mixins.scss";
.table-loader-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.detail-loader {
  margin-top: -300px;
  margin-bottom: -350px;
}

.loading-dropdown {
  display: block;
  margin-top: -10px;
  @include text(400, 14px, 16px, $gray-500);
}

.cl-margin-padding {
  margin: 0;
  padding: 0;
}

.child-row td:first-child {
  padding-left: 35px !important;
}

.grand-child-row td:first-child {
  padding-left: 55px !important;
}

.h--25 {
  height: 25vh;
}

.h--35 {
  height: 35vh;
}

.h--50 {
  height: 50vh;
}

.h--75 {
  height: 75vh;
}

.h--100 {
  height: 100vh;
}

.pg_loader {
  .primary-text {
    @include text(400, 14px, 16px, $spn-700-color);
    text-align: center;
    letter-spacing: 0.4px;
  }

  .secondary-text {
    @include text(600, 16px, 16px, $pure-black);
    text-align: center;
    letter-spacing: 0.4px;
  }
}
