@import "../../assets/styles/common/colors.scss";
@import "../../assets/styles/common/mixins.scss";

.max-w-400 {
  max-width: 400px;
}

.sp-modal {
  &__title {
    @include text(700, 22px, normal, $pure-black);
    letter-spacing: 0.25px;
  }

  &__subtitle {
    @include text(400, 14px, 16px, $spn-500-color);
    letter-spacing: 0.4px;
  }

  &__body {
    padding-top: 0px !important;
    position: static;
  }

  .gap-4 {
    gap: 1rem;
  }
}

.instr {
  @include text(600, 12px, 24px, $spn-900-color);
  border-radius: 8px;
  border: 1px solid $spy-500-color;
  background-color: $spy-100-color;
  letter-spacing: 0.2px;
  padding: 10px 1rem;
}
