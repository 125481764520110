@import "../../assets/styles/common/colors.scss";
@import "../../assets/styles/common/mixins.scss";

.custom-accordion {
  width: 100%;
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  border-radius: 8px;

  &.gap-1 {
    gap: 1rem;
  }
  &.gap-15 {
    gap: 1.5rem;
  }

  &.gap-2 {
    gap: 2rem;
  }
  &.gap-3 {
    gap: 3rem;
  }
  &.gap-4 {
    gap: 4rem;
  }

  &-item {
    border-radius: 8px;
    border: 1px solid $gray-100;
    background: $spn-050-color;

    &__title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      @include text(600, 16px, 24px, $spn-900-color);
      letter-spacing: 0.2px;
      cursor: pointer;
      &:hover {
        background-color: $gray-100;
      }
    }

    &__content {
    }
    &__title,
    &__content {
      padding: 1rem;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 0.3s ease-in-out;
}
