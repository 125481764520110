// @import "~bootstrap/dist/css/bootstrap.min.css";
@import "~bootstrap/scss/bootstrap.scss";
@import "./common/colors.scss";

/* bootstrap overwrite */

.modal-header {
  border-bottom: none;
  .close {
    border: none;
    background-color: transparent;
    font-size: 1.5rem;
  }
}
.modal-body {
  padding: 1rem 1.5rem;
}
.modal-footer {
  border-top: none;
  padding: 0.5rem 1.5rem 1.5rem 1.5rem;
}
.modal-footer > * {
  margin: 0;
}

.form-control {
  border: 1px solid $gray-100;
  border-radius: 4px;
  height: 48px;
  letter-spacing: -0.05px;
  color: $dark-200;
}

.form-label {
  color: $dark-100;
  font-size: 14px;
}
.form-control:focus {
  border-color: $primary-color;
  border-width: 2px;
}

.form-control:focus,
.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  box-shadow: none;
}

.modal-title {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 16px;
}

.nav-tabs {
  .nav-item {
    margin-right: 1.5rem;

    &.show {
      .nav-link,
      .nav-link.active {
        border: none;
        border-bottom: 2px solid $primary-color;
        color: $primary-color;
      }
    }

    .nav-link {
      transition: none;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.002em;
      padding-left: 1px;
      padding-right: 1px;

      &.active {
        border: none;
        border-bottom: 2px solid $primary-color;
        color: $primary-color;
      }

      &:hover {
        border-color: transparent;
        cursor: pointer;
      }

      &.active {
        &:hover {
          border-bottom: 2px solid $primary-color;
        }
      }
    }
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 480px;
  }
}
.form-switch {
  .switch_input {
    border: 0;
    background-color: #d4d9dd;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");

    &:checked {
      background-color: $spb-500-color;
    }
    &:focus {
      box-shadow: none;
    }
  }
}
