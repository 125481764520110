@import "../../assets/styles/common/colors.scss";
@import "~vgg-ui-react/dist/styles/partials";

.transactions-container {
  padding: 16px 0;
  .dropdown-button {
    padding: 12px;
  }
  .bg-spn-gray {
    background-color: $spn-050-color;
  }
  .transactionStatus {
    text-align: center;
    border-radius: 4px;
    text-transform: uppercase;
    width: max-content;
    height: 24px;
    padding: 0.8rem;
    display: flex;
    align-items: center;
  }
  .success {
    color: $spg-500-color;
    background: $spg-100-color;
  }

  .failed {
    background-color: $spr-100-color;
    color: $spr-500-color;
  }
  .header {
    margin-bottom: 16px;
    padding: 16px;
  }
  .search-container {
    .search-input {
      width: 300px;
      height: 40px !important;
      padding-right: 30px;
    }
    i {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
  .view-button {
    font-size: $sp-fontsize--14;
  }
}
