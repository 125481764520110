@import "./colors.scss";

@mixin text($fontweight, $fontsize, $lineheight, $color, $opacity: 1) {
  font-family: "inter";
  font-style: normal;
  font-weight: $fontweight;
  font-size: $fontsize;
  line-height: $lineheight;
  color: $color;
  opacity: $opacity;
}

@mixin section($padding) {
  padding: $padding;
}

@mixin content-header {
  @include text(600, 28px, 32px, $spb-800-color);
}

@mixin layoutPadding {
  padding-left: 15px;
  padding-right: 15px;
}

@mixin contentPadding {
  padding: 5px;
}
