@import "../../assets/styles/common/colors.scss";
@import "../../assets/styles/common/mixins.scss";

.toggle-btn {
  background: transparent;
  border: 1px solid $gray-100 !important;
  border-radius: 4px;
  padding: 11px;
  transition: all 0.4s;
  @include text(400, 16px, 16px, $spn-500-color);

  &.gray {
    background: $spn-050-color;
  }

  &:focus {
    box-shadow: none;
  }
  &:hover {
    background: $spn-150-color;
  }
}
