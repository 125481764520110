@import '../../../assets/styles/common/colors.scss';
@import '../../../assets/styles/common/mixins.scss';
.currencies-header {
  &__title {
     @include text(600, 20px, 16px, $pure-black);
  }

  &__sub-title {
    @include text(400, 12px, 16px, $dark-100);
    letter-spacing: 0.4px;
  }
}