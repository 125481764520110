@import "assets/styles/bootstrap-override.scss";
@import "~vgg-ui-react/dist/styles/index.scss";
@import "~react-date-range/dist/styles.css"; // main style file
@import "~react-date-range/dist/theme/default.css"; // theme css file

* {
  scrollbar-width: thin;
  scrollbar-color: $spb-600-color $spn-200-color;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* width */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $spn-200-color;
  border-radius: 5px;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: $spb-600-color;
  border-radius: 5px;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: $spb-800-color;
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Inter", "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bg-gray {
  background-color: $gray-060;
}

.pagination-container {
  padding: 5px !important;
  .pagination-item {
    font-size: 14px !important;

    &.selected {
      color: $primary-color;
      background-color: $blue-900 !important;
    }
  }
}

.pagination-nav {
  font-size: 14px !important;
  display: flex;
  align-items: center;
}

.shimmer-line {
  width: 100%;
  height: 8px;
  align-self: center;
  border-radius: 8px;
}

@mixin fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.2s;
}

@mixin fade-out {
  opacity: 0;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.2s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade_in {
  @include fade-in;
}

.fade_out {
  @include fade-out;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: $gray-055 !important;
}
.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: $gray-055 !important;
}

.table td {
  border-top: none;
  padding: 16px;
}
.table th {
  padding: 16px;
}

.table > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table thead {
  background-color: hsl(221, 90%, 95%);
}

.app-wrapper {
  min-height: 50vh;
}

.min-h-65 {
  min-height: 65vh;
}

.min-h-65 {
  min-height: 65vh;
}

.drawer {
  &.fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
  }

  @keyframes fadeInRight {
    from {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  @-webkit-keyframes fadeInRight {
    from {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  &.modal-dialog {
    margin: 0;
    height: 100%;
  }

  &.w-480 {
    max-width: 480px;
  }

  &.animated {
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  .modal-header {
    border-radius: 0;
    align-items: center;

    .modal-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-left: 10px;
    }
  }

  .modal-content {
    border-radius: 0;
    border: 0;
    -webkit-box-shadow: -12px 0 38px -14px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: -12px 0 38px -14px rgba(0, 0, 0, 0.25);
    box-shadow: -12px 0 38px -14px rgba(0, 0, 0, 0.25);
    background-clip: padding-box;
    min-height: 100vh;
  }

  .modal-backdrop {
    background-color: transparent;
  }

  .modal-button-container {
    margin: 0 auto;
    width: 90%;
    max-width: 500px;
  }

  .btn {
    border-radius: 0;
  }
}

.rdrStaticRange {
  color: $dark-200;
}

.pg_loader .spinner-border {
  --bs-spinner-width: 5rem;
  --bs-spinner-height: 5rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: 2px solid currentcolor;
  border-right-color: #d7dce0;
  border-bottom-color: #d7dce0;
  border-left-color: #d7dce0;
}

.outline-light {
  border: 1px solid $spn-100-color;
}

button {
  border-radius: 8px !important;
}

/* Datepicker Styles override */

.react-datepicker {
  border: 1px solid $spn-050-color;
  .react-datepicker__header {
    background-color: $pure-white;
    border-bottom: none;
  }

  .react-datepicker-popper[data-placement^="bottom"] {
    .react-datepicker__triangle::before {
      border-bottom-color: inherit !important;
      display: none;
    }
  }

  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::after {
    border-top: none;
    border-bottom-color: transparent;
  }

  .react-datepicker-popper[data-placement^="top"]
    .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="top"]
    .react-datepicker__triangle::after,
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::after {
    border: unset !important;
    border-width: 0px !important;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: $spb-900-color;
    line-height: 2.2rem;
    margin: 0.166rem;
    height: 35px;
    width: 35px;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: $primary-color;
    color: $pure-white;
    border-radius: 50%;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 50%;
    background-color: $primary-color;
    color: $pure-white !important;
  }
}
/* End Datepicker styles override */

@import "react-datepicker/dist/react-datepicker.css";
