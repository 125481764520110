@import "~vgg-ui-react/dist/styles/partials";
@import "../../assets/styles/common/colors.scss";
@import "../../assets/styles/common/mixins.scss";

.onboard-wrapper {
  .card-section {
    background: $spb-050-color;
    border: 1px solid $gray-100;
    border-radius: 4px;

    .content-paragraph {
      letter-spacing: -0.048px;
       @include text(400, 16px, 24px, $dark-100);
      }
      
      .footnote {
      @include text(500, 14px, 16px, $spb-900-color);
      letter-spacing: 0.4px;
    }
  }
  
  .onboard-btn {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.4px;
  }
            
  .learn-more-btn {
    background: $pure-white;
    border: 1px solid $gray-100;
    @include text(500, 14px, 16px, $darkBlue);
    letter-spacing: 0.4px;
  }
}
